import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

let GalleryLinks = ({ gallery }) => {
  const [galleryStyle, setGalleryStyle] = useState();

  const handleClick = (e) => {
    gallery(e);
    setGalleryStyle(e);
  };

  return (
    <div className='gallery-logo-container'>
      <Fade left>
        <div onClick={() => handleClick(0)} className={`gallery-logo ${galleryStyle === 0 && `selected-gallery`}`}>
          <h3>event</h3>
          <img src='https://i.imgur.com/mDHneHk.jpg' alt='event-img' />
        </div>
      </Fade>
      <Fade left>
        <div onClick={() => handleClick(1)} className={`gallery-logo ${galleryStyle === 1 && `selected-gallery`}`}>
          <h3>brand</h3>
          <img src='https://i.imgur.com/6RQS6xl.jpg' alt='brand-img' />
        </div>
      </Fade>
      <Fade left>
        <div onClick={() => handleClick(2)} className={`gallery-logo ${galleryStyle === 2 && `selected-gallery`}`}>
          <h3>personality</h3>
          <img src='https://i.imgur.com/DeoehGo.jpg' alt='personality-img' />
        </div>
      </Fade>
      <Fade left>
        <div onClick={() => handleClick(3)} className={`gallery-logo ${galleryStyle === 3 && `selected-gallery`}`}>
          <h3>dance</h3>
          <img src='https://i.imgur.com/1ryCJxg.jpg' alt='dance-img' />
        </div>
      </Fade>
      <Fade left>
        <div onClick={() => handleClick(4)} className={`gallery-logo ${galleryStyle === 4 && `selected-gallery`}`}>
          <h3>music</h3>
          <img src='https://i.imgur.com/kJfbYrb.jpg' alt='music-img' />
        </div>
      </Fade>
      <Fade left>
        <div onClick={() => handleClick(5)} className={`gallery-logo ${galleryStyle === 5 && `selected-gallery`}`}>
          <h3>sport</h3>
          <img src='https://i.imgur.com/iRnoGnC.jpg' alt='sport-img' />
        </div>
      </Fade>
    </div>
  );
};

export default GalleryLinks;
