import React from 'react';
import RedButton from './RedButton';

let InfoHeading = ({ heading1, heading2, buttonText, navigate }) => {
  return (
    <div className='info-heading-container'>
      <h1>{heading1}</h1>
      <h2>{heading2}</h2>
      {buttonText === undefined ? null : <RedButton text={buttonText} navigate={() => navigate(buttonText)} />}
    </div>
  );
};

export default InfoHeading;
