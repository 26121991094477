import React from 'react';
import logos from '../logos';
import Fade from 'react-reveal/Fade';

let SmallLogos = () => {
  return (
    <div className='image-logo-block'>
      {logos.map((l, i) => (
        <Fade left key={i}>
          <img src={l.img} alt={l.title} index={i}></img>
        </Fade>
      ))}
    </div>
  );
};

export default SmallLogos;
