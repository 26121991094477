import React from 'react';
import { Link } from 'react-router-dom';

const RedButton = ({ text }) => {
  const toTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link onClick={toTop} className='red-btn' to='/photography'>
      {text}
    </Link>
  );
};

export default RedButton;
