import React from 'react';
import Fade from 'react-reveal/Fade';

let BackgroundImage = (props) => {
  return (
    <div style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ),url(${props.img})` }} className='image-background'>
      <Fade>
        <h1>{props.heading}</h1>
      </Fade>
    </div>
  );
};

export default BackgroundImage;
