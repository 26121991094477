import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Home from './Home';
import Nav from './Nav';
import SubFooter from './SubFooter';
import Footer from './Footer';
import Photography from './Photography';
import Biog from './Biog';

let App = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Nav />
      <Switch>
        <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
        <Route exact path='/' component={Home} />
        <Route exact path='/photography/' component={Photography} />
        <Route exact path='/biography/' component={Biog} />
      </Switch>
      <SubFooter />
      <Footer />
    </>
  );
};

export default App;
