import React from 'react';
import { Link } from 'react-router-dom';

let Nav = () => {
  return (
    <nav>
      <Link className="nav-logo" to="/">
        <img className="swp-logo" name="home" src="https://i.imgur.com/0b8sKEL.png" alt="SWP logo" />
      </Link>
      <div>
        <Link className="nav-link" to="/photography">
          photography
        </Link>
        <Link className="nav-link" to="/biography">
          biography
        </Link>
      </div>
    </nav>
  );
};

export default Nav;
