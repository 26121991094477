import React from 'react';
import InfoTextItem from './InfoTextItem';
import Fade from 'react-reveal/Fade';

let InfoTextBlock = () => {
  return (
    <section className='info-block-container'>
      <Fade left>
        <InfoTextItem title='Creative' content='stephen wright photography draws on its extensive experience in the arts to deliver consistently creative and unique images' />
      </Fade>
      <Fade left>
        <InfoTextItem
          title='Experienced'
          content='we are proud to have worked with a large number of leading clients in the uk and europe providing event coverage, live editing and headline images to accompany media campaigns'
        />
      </Fade>
      <Fade left>
        <InfoTextItem
          title='Fast Delivery'
          content='we know clients need their images delivered asap. at stephen wright photography we can deliver fully edited galleries within 24 hours of an event taking place or even quicker if necessary'
        />
      </Fade>
      <Fade left>
        <InfoTextItem
          title='Personal Service'
          content='we work closely with our clients to create unique briefs to suit the event. with clear communication from booking to delivery we ensure nothing is left to chance'
        />
      </Fade>
    </section>
  );
};

export default InfoTextBlock;
