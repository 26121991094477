import React from 'react';
import { Link } from 'react-router-dom';

let Footer = () => {
  const toTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <div className='footer-nav-link-container'>
        <Link onClick={toTop} className='footer-nav-links' to='/'>
          home
        </Link>
        <Link onClick={toTop} className='footer-nav-links' to='/photography'>
          photography
        </Link>
        <Link onClick={toTop} className='footer-nav-links' to='/biography'>
          biography
        </Link>
      </div>
      <div className='footer-info'>
        <a href='mailto:stephen@stephenwrightphotography.co.uk'>stephen@stephenwrightphotography.co.uk</a>
        <p>all images © Stephen Wright {new Date().getFullYear()}</p>
        <p>
          code & design <a href='https://sp-wright.github.io/portfolio/'>SP-Wright</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
