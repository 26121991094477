const brandImg = [
  {
    img: 'https://i.imgur.com/3cMAIvr.jpg',
    name: 'campari bottles',
  },
  {
    img: 'https://i.imgur.com/n7u2Leo.jpg',
    name: 'man holding baileys',
  },
  {
    img: 'https://i.imgur.com/McLROGT.jpg',
    name: 'children cheering',
  },
  {
    img: 'https://i.imgur.com/7DCM82c.jpg',
    name: 'boy holds sportsAid sign',
  },
  {
    img: 'https://i.imgur.com/o2Ukztc.jpg',
    name: 'air china',
  },
  {
    img: 'https://i.imgur.com/WOHZGN1.jpg',
    name: 'boys celebrating',
  },
  {
    img: 'https://i.imgur.com/ork9Oms.jpg',
    name: 'rambert building exterior',
  },
  {
    img: 'https://i.imgur.com/wVxLxsI.jpg',
    name: 'welsh guards',
  },
  {
    img: 'https://i.imgur.com/1ZsAIRf.jpg',
    name: 'rugby players and greene king ipa',
  },
  {
    img: 'https://i.imgur.com/BqPMJ1k.jpg',
    name: 'women pours baileys drink',
  },
  {
    img: 'https://i.imgur.com/1gomBb3.jpg',
    name: 'garsington opera exterior',
  },
  {
    img: 'https://i.imgur.com/baZUC0m.jpg',
    name: 'tennis players',
  },
  {
    img: 'https://i.imgur.com/QuW4Esl.jpg',
    name: 'nissan leaf',
  },
  {
    img: 'https://i.imgur.com/fNe8ZjG.jpg',
    name: 'yankee candle',
  },
  {
    img: 'https://i.imgur.com/IxdDECh.jpg',
    name: 'benecos products',
  },
  {
    img: 'https://i.imgur.com/6XJXLyb.jpg',
    name: 'cricket world cup branding',
  },
  {
    img: 'https://i.imgur.com/fUEiQry.jpg',
    name: 'cricket world cup branding',
  },
  {
    img: 'https://i.imgur.com/qS5yL8Z.jpg',
    name: 'man makes campari cocktail',
  },
  {
    img: 'https://i.imgur.com/HJDfiG0.jpg',
    name: 'wildcats branding',
  },
  {
    img: 'https://i.imgur.com/ffrzLZY.jpg',
    name: 'rugby players pouring pints',
  },
  {
    img: 'https://i.imgur.com/0TAwYlr.jpg',
    name: 'man makes cocktail',
  },
  {
    img: 'https://i.imgur.com/ANgQkGm.jpg',
    name: 'campari bottles',
  },
  {
    img: 'https://i.imgur.com/WWGgIDw.jpg',
    name: 'baileys and books',
  },
  {
    img: 'https://i.imgur.com/rlhisUw.jpg',
    name: 'purple bricks branding',
  },
  {
    img: 'https://i.imgur.com/gRLjWaq.jpg',
    name: 'the crystal building',
  },
  {
    img: 'https://i.imgur.com/tCXHN8n.jpg',
    name: 'yankee candle',
  },
  {
    img: 'https://i.imgur.com/YWHB0Gq.jpg',
    name: 'baileys branding',
  },
  {
    img: 'https://i.imgur.com/XzJubKp.jpg',
    name: 'greg rutherford training',
  },
  {
    img: 'https://i.imgur.com/k6ck1vV.jpg',
    name: 'girls cheering',
  },
  {
    img: 'https://i.imgur.com/3XN6XFJ.jpg',
    name: 'sainsbury`s branding',
  },
];

export default brandImg;
